<template>
  <section class="featured-projects">
    <div class="container">
      <div class="featured-projects__top">
        {{ t("home.featured_projects") }}
      </div>
      <div>
        <client-only>
          <hb-transition :overflowed="isTablet">
            <Splide
              :has-track="false"
              :options="projectCarouselSettings"
              class="project-carousel"
            >
              <div class="custom-wrapper">
                <SplideTrack>
                  <SplideSlide
                    v-for="(project, index) in projects"
                    :key="index"
                    :index="index"
                  >
                    <project-card :project="project" list-item />
                  </SplideSlide>
                </SplideTrack>

                <ul class="splide__pagination"></ul>

                <div class="project-carousel__footer">
                  <slot name="footer-right"></slot>
                </div>
              </div>
            </Splide>
          </hb-transition>
        </client-only>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import type { Options as SplideOptions } from "@splidejs/vue-splide";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import { computed } from "vue";
import ProjectCard from "./ProjectCard.vue";
import { useI18n } from "#imports";
import HbTransition from "~/components/base/utils/HbTransition.vue";
import { useViewTools } from "~/composables";

export default {
  name: "FeaturedProjects",
  components: {
    ProjectCard,
    HbTransition,
    Splide,
    SplideSlide,
    SplideTrack,
  },
  setup(props, ctx) {
    const { t } = useI18n();
    const projectCarouselSettings = computed<SplideOptions>(() => ({
      type: "slide",
      perPage: 4,
      gap: "15px",
      arrows: false,
      drag: false,
      breakpoints: {
        1150: {
          perPage: 3,
        },
        990: {
          perPage: 2,
        },
        768: {
          destroy: true,
        },
      },
    }));

    return {
      projectCarouselSettings,
      t,
      ...useViewTools(),
    };
  },
  data: () => ({
    projects: [
      {
        image:
          'https://storage.googleapis.com/homebourse-public-staging/project/8/Gallery/Canaletto-Dining.jpg',
        name: 'Cipriani Residences Miami',
        address: '1420 South Miami Avenue, Miami, Florida 33130',
        slug: 'cipriani-residences-miami',
        features: [
          {
            type: 'unitNumber',
            value: 397,
          },
          {
            type: 'sqft',
            value: '1194-3495 sqft',
          },
          {
            type: 'bedrooms',
            value: '1-4',
          },
          {
            type: 'bathrooms',
            value: '2-4.5',
          },
          {
            type: 'priceRange',
            value: '$1.9M-7M',
          },
        ],
        status: 'active',
      },
      {
        image:
          'https://storage.googleapis.com/homebourse-public-staging/project/13/Vita%20at%20Grove%20Isle/Gallery/Balcony%202.jpg',
        name: 'Vita at Grove Isle',
        address: '5 Grove Isle Drive, Miami, Florida 33133',
        slug: 'vita-at-grove-isle',
        features: [
          {
            type: 'unitNumber',
            value: 65,
          },
          {
            type: 'sqft',
            value: '3173-6102 sqft',
          },
          {
            type: 'bedrooms',
            value: '3-4',
          },
          {
            type: 'bathrooms',
            value: '3.5-5.5',
          },
          {
            type: 'priceRange',
            value: '$5.8M-23M',
          },
        ],
        status: 'active',
      },
      {
        image:
          'https://storage.googleapis.com/homebourse-public-prod/project/16/6620476f1f048_Gallery-3/Gallery-3.jpg',
        name: 'CasaBella By B&B Italia',
        address: '1400 Biscayne Blvd, Miami, Florida 33132',
        slug: 'casabella',
        features: [
          {
            type: 'unitNumber',
            value: 319,
          },
          {
            type: 'sqft',
            value: '992-8263 sqft',
          },
          {
            type: 'bedrooms',
            value: '1-5',
          },
          {
            type: 'bathrooms',
            value: '1-6',
          },
          {
            type: 'priceRange',
            value: '$1.3M-10M',
          },
        ],
        status: 'active',
      },
      {
        image:
          'https://storage.googleapis.com/homebourse-public-staging/project/3/Hero.jpg',
        name: 'Andare Residences',
        address: '521 E Las Olas Blvd. Fort Lauderdale, FL 33301',
        slug: 'andare',
        features: [
          {
            type: 'completion',
            value: 2027,
          },
          {
            type: 'unitNumber',
            value: 163,
          },
          {
            type: 'sqft',
            value: '1812-4328 sqft',
          },
          {
            type: 'bedrooms',
            value: '2-5',
          },
          {
            type: 'bathrooms',
            value: '2.5-5.5',
          },
          {
            type: 'priceRange',
            value: '$2.13M-5.5M',
          },
        ],
        status: 'active',
      },
    ],
  }),
};
</script>

<style lang="scss">
.featured-projects {
  padding: 60px 0 30px;

  .container {
    @include container;

    @include desktop {
      padding: 0 40px;
    }

    @include tablet {
      max-width: 100%;
      padding: 0;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    font-size: 30px;
    line-height: 2;
    font-weight: 700;

    @include tablet {
      max-width: var(--hb-container-max-width);
      padding: 0 var(--hb-container-padding-x);
      margin: 0 auto 35px;

      font-size: 26px;
      line-height: 34px;
    }
  }
}

.project-carousel {
  @include tablet {
    margin: 0;
  }

  @media (min-width: 769px) {
    &.splide.is-initialized:not(.is-active) .splide__list {
      display: flex;

      .splide__slide {
        width: calc(((100% + 15px) / 4) - 15px);
        margin-right: 15px;

        @media (max-width: 1150px) {
          width: calc(((100% + 15px) / 3) - 15px);
        }

        @media (max-width: 990px) {
          width: calc(((100% + 15px) / 2) - 15px);
        }
      }
    }
  }

  &__footer {
    @include mobile {
      margin-top: 40px;
      padding: 0 30px;
      justify-content: space-between;
    }
  }

  .splide {
    &__track {
      overflow: unset;
    }

    &__pagination {
      max-width: var(--hb-container-max-width);
      padding: 0 40px;
      margin: 70px auto 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      @include tablet {
        display: none;
      }

      &__page {
        margin: 0;
        padding: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--hb-gray2);
        transition: background-color 0.5s ease;
        border: 0;
        cursor: pointer;

        &::after {
          content: unset;
        }

        &.is-active {
          background-color: var(--hb-blue1);
        }
      }
    }

    @include tablet {
      &__list {
        display: flex !important;
        flex-direction: row;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        padding: 0 15px !important;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__slide {
        width: 320px;
        margin: 0 7px;
        scroll-snap-align: center;
      }
    }
  }
}
</style>
